import React from  'react';
import {connect} from 'react-redux';
import NumberImagesPrinter from '../../components/Numbers/NumberImagesPrinter'
import letters from '../../utils/tigrinyaAlphabets';
import {incAudibleAction, decAudibleAction} from '../../Action';
import AudibleDisiplay from '../../components/Audibles/AudibleDisplay';

/**
 * Wrapper component for showing audible stories with "next" and "prev" buttons
 *  eg.
 *     [image]
 * Some description
 *     |> Audio
 */
class Audibles extends React.PureComponent {
    state= {
        focusedAlphabet: null,
    }
    increment = () => {
        this.setState({focusedAlphabet: null}, () => this.props.dispatch(incAudibleAction()));
    };

    decrement = () => {
        this.setState({focusedAlphabet: null}, () => this.props.dispatch(decAudibleAction()));
    };

    handle = (v) => {
        this.setState({ focusedAlphabet: v});
    };

    render() {
        console.log("seez props", this.props);
        let counter = this.props.audibleIndex;
        const listOfLetters = Object.values(letters).filter(d => d.main);

        return (
            <>
                <AudibleDisiplay
                    audibleIndex= {counter}
                    onClickIncrement={this.increment}
                    onClickDecrement={this.decrement}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
      audibleIndex: state.audibleIndex ? state.audibleIndex : 0,
    };
  }

export default connect(mapStateToProps)(Audibles);
