import React from  'react';
import {connect} from 'react-redux';
import AlphabetBouncing from '../../components/AlphabetAnimation/AlphabetBouncing';
import letters from '../../utils/tigrinyaAlphabets';
import {incAction, decAction} from '../../Action';

/**
 * Wrapper component for showing each alphabet page by page with "next" and "prev" buttons
 *  eg.
 *  ሀ -> 🐒
 *  ሀ ሁ ሂ ሃ ሄ ህ ሆ
 */
class Alphabet extends React.PureComponent {
    state= {
        focusedAlphabet: null,
    }
    increment = () => {
        this.setState({focusedAlphabet: null}, () => this.props.dispatch(incAction()));
    };

    decrement = () => {
        this.setState({focusedAlphabet: null}, () => this.props.dispatch(decAction()));
    };

    handle = (v) => {
        this.setState({ focusedAlphabet: v});
    };

    render() {
        let counter = this.props.count;
        if (this.props.count < 0 || this.props.count > 30) {
            counter = 0;
        }
        const listOfLetters = Object.values(letters).filter(d => d.main);

        return (
                <AlphabetBouncing
                    count= {counter}
                    alphabets={listOfLetters}
                    onClickIncrement={this.increment}
                    onClickDecrement={this.decrement}
                    focusedAlphabet={this.state.focusedAlphabet ? this.state.focusedAlphabet : listOfLetters[counter].letter[0].lable}
                    updateFocusedAlphabet={this.handle}
                />
        );
    }
}

function mapStateToProps(state) {
    return {
      count: state.count ? state.count : 0,
    };
  }

export default connect(mapStateToProps)(Alphabet);
