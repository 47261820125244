import React from 'react';
import { toTigrinya } from '../../utils/toTigrinya';
import * as siteImages from '../../resources/assets/images/site-images';
import { Autorenew } from '@material-ui/icons';
import {Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";

class Home extends React.PureComponent {
    state = {
        isAuthenticated: false,
    }

    login = () => {
        this.props.auth.login();
    };

    static getDerivedStateFromProps = (nextProps, prevState) => {
        return {
            isAuthenticated: typeof nextProps.auth !== 'undefined' && nextProps.auth.isAuthenticated(),
        };
    };

    render () {
        return (
            <div className="home" style={{margin: '0px -24px'}}>

            {this.state.isAuthenticated
                ? this.props.history.push('/kindergarten')
                : <div>
                    <div style= {{display:'flex', justifyContent: 'center', 'flexDirection': 'column', marginTop:'-85px'}}>
                        <div style={{ display: 'flex', position: 'relative',}}>
                            <img src={siteImages.banner} style={{'width': '100%'}} />
                            {!this.props.auth.isAuthenticated() &&
                                <div style={{position: 'absolute', right: '3px'}}>
                                    <Tooltip title="Log in">
                                        <Button color="inherit" onClick={this.login}>{toTigrinya('arHu')}</Button>
                                    </Tooltip>
                            </div>
                            }
                        </div>
                        <div style={{'height': '385px', 'width': '100%', backgroundColor: '#EFEFEF', display:'flex', justifyContent: 'center', 'flexDirection': 'row'}}>
                            <div style={{'height': '350px', 'width': '15%', minWidth: '240px', border: '1px solid #DDDDDD', backgroundColor: '#FFFFFF', margin: '10px'}}>
                                <div><img src={siteImages.icon1} style={{'width': '100%'}} /></div>
                                <div style={{textAlign: 'center', fontSize: '54px'}}>ፊደላት</div>
                                <div style={{fontSize: '18px', padding: '43px 20px'}}>ፊደላት ምስ ቃላትን ስእልታትን እናዛመድና ንመሃር</div>
                            </div>
                            <div style={{'height': '350px', 'width': '15%', minWidth: '240px', border: '1px solid #DDDDDD', backgroundColor: '#FFFFFF', margin: '10px'}}>
                                <div><img src={siteImages.icon2} style={{'width': '100%'}} /></div>
                                <div style={{textAlign: 'center', fontSize: '54px'}}>ዛንታታት</div>
                                <div style={{fontSize: '18px', padding: '43px 20px'}}>መሰጥቲ ዝንበቡን ዝስምዑን ዛንታታት ብጽሑፍ ዝተሰነየ ትሕዝቶ</div>
                            </div>
                            <div style={{'height': '350px', 'width': '15%', minWidth: '240px', border: '1px solid #DDDDDD', backgroundColor: '#FFFFFF', margin: '10px'}}>
                                <div><img src={siteImages.icon3} style={{'width': '100%'}} /></div>
                                <div style={{textAlign: 'center', fontSize: '54px'}}>ቁጽርታት</div>
                                <div style={{fontSize: '18px', padding: '43px 20px'}}>መባእታ ሙቅጻር፣ ምድማር፣ ምርባሕ እንዳተጻወትና ንመሃር</div>
                            </div>
                        </div>
                        <div style={{'height': '385px', 'width': '100%', backgroundColor: '#EFEFEF', display:'flex', justifyContent: 'center', 'flexDirection': 'row'}}>
                            <div style={{'height': '350px', 'width': '15%', minWidth: '240px', border: '1px solid #DDDDDD', backgroundColor: '#FFFFFF', margin: '10px'}}>
                                <div><img src={siteImages.icon5} style={{'width': '100%'}} /></div>
                                <div style={{textAlign: 'center', fontSize: '54px'}}>ዝርርብ</div>
                                <div style={{fontSize: '18px', padding: '43px 20px'}}>መዓልታዊ ዝርርብ፡ ሕቶታትን መልስን</div>
                            </div>
                            <div style={{'height': '350px', 'width': '15%', minWidth: '240px', border: '1px solid #DDDDDD', backgroundColor: '#FFFFFF', margin: '10px'}}>
                                <div><img src={siteImages.icon4}style={{'width': '100%'}} /></div>
                                <div style={{textAlign: 'center', fontSize: '54px'}}>ጸወታታት</div>
                                <div style={{fontSize: '18px', padding: '43px 20px'}}>መሃሪ ጸወታታትን ዝተመሳቀለ ሕቶታትን</div>
                            </div>
                            <div style={{'height': '350px', 'width': '15%', minWidth: '240px', border: '1px solid #DDDDDD', backgroundColor: '#FFFFFF', margin: '10px'}}>
                                <div><img src={siteImages.icon6} style={{'width': '100%'}} /></div>
                                <div style={{textAlign: 'center', fontSize: '54px'}}>ልምድታት</div>
                                <div style={{fontSize: '18px', padding: '43px 20px'}}>ንነኣሽቱ ዝኸውን ልምድታትን መዓልታዊ መደባትን</div>
                            </div>
                        </div>
                        <div style={{'height': '180px', 'width': '100%', display:'flex', justifyContent: 'center', fontSize: '68px', padding: '43px 20px'}}>
                            ዘንብብ ይዕንብብ!
                        </div>
                        <div style={{'height': '440px', 'width': '100%', backgroundColor: '#EFEFEF', display:'flex', justifyContent: 'center', 'flexDirection': 'row', padding: '15px'}}>
                            <div style={{'height': '400px', 'width': '20%', minWidth: '240px', alignItems: 'center'}}>
                                <img src={siteImages.grandParents} style={{'width': '100%'}} />
                            </div>
                            <div style={{'height': '400px', 'width': '20%', minWidth: '240px', fontSize: '28px', padding: '15px', alignItems: 'center'}}>
                                ደቕኹም መንነቶም ክፈልጡን፤ መቐረት ናይ ወለድን፤ ባህልን ልምድን ንኽፈልጡ፤
                                ኣብ ዓዶም ከይጋየሹን
                                ቋንቋ ወለዶም መሃርዎም
                            </div>
                        </div>
                    </div>
                 </div>
            }

            </div>
        );

    }
}

export default Home;
