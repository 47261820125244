import React from 'react';
import MediaCard from './MediaCard';
import * as cartoons from '../../resources/assets/images';

const cards = [
    {key: 'alphabet', title: 'fidelat tgrNa', path: '/alphabet', img: cartoons.fidel, detail: 'Hade b Hade enda tezenagaOna fidelat tgrNa b sAln dmxn nmehar'},
    {key: 'alphabets', title: 'fidelat tgrNa', path: '/alphabets', img: cartoons.hahu, detail: 'Anda tezenagaOna fidelat tgrNa b sAln dmxn nmehar'},
    {key: 'alphabetChart', title: 'kulom fidelat', path: '/alphabet-chart', img: cartoons.hahu, detail: 'kulom fidelat bHansab nmeharom'},
    {key: 'numbers', title: 'quxrtat',  path: '/numbers', img: cartoons.numbers, detail: 'Anda tezenagaOna quxrtat nmehar'},
    {key: 'numberSingles', title: 'quxrtat',  path: '/number-singles', img: cartoons.coutingSingles, detail: 'quxrtat bebHade muqxar nlamed'},
    {key: 'color', title: 'Hbrtat',  path: '/colors', img: cartoons.colors, detail: ' Hade b Hade enda tezenagaOna Hbrtat b sAln dmxn nmehar'},
    {key: 'conversation', title: 'zrrb',  path: '/conversation', img: cartoons.conversation, detail: 'mebaEtawi zrrb nlamed'},
    {key: 'routines', title: 'srOatat',  path: '/routines', img: cartoons.routine, detail: 'sne srOat nlamed'},
    {key: 'shapes', title: 'qrxtat',  path: '/shapes', img: cartoons.shapes, detail: 'ztefelaleyu qirxtatn Hbritatn nfletom'},
    {key: 'audibleStories', title: 'zantatat',  path: '/audible-stories', img: cartoons.audible, detail: 'nsmaO zantatat. xnxway wedekoy xelimay...'},
    {key: 'songs', title: 'mezmur',  path: '/songs', img: cartoons.shapes, detail: 'mezamur nay hxanat'},
    {key: 'readAlphabets', title: 'znbeb fidelat',  path: '/read-alphabets', img: cartoons.familyReadingColor, detail: 'fidelat ms nbab.'},
    {key: 'memory_game', title: 'xeweta',  path: '/memory-game', img: cartoons.brain, detail: ' Anda tezenagaOna Hangolna mzKKar nelamd'},

];

class MediaCards extends React.PureComponent {
    render () {
        return (
            <div className="alphabet-cards">
                {cards.map (card => (
                    <MediaCard key={card.key} card={card} />
                ))}
            </div>
        );

    }
}

export default MediaCards;
