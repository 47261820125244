import React from  'react';
import {connect} from 'react-redux';
import AlphabetBouncing from '../../components/AlphabetAnimation/AlphabetBouncing';
import NumberImagesPrinter from '../../components/Numbers/NumberImagesPrinter'
import letters from '../../utils/tigrinyaAlphabets';
import {incNumbersAction, decNumbersAction} from '../../Action';

/**
 * Wrapper component for showing each alphabet page by page with "next" and "prev" buttons
 *  eg.
 *  ሀ -> hibey.jpg
 *  ሀ ሁ ሂ ሃ ሄ ህ ሆ
 */
class NumberSingles extends React.PureComponent {
    state= {
        focusedAlphabet: null,
    }
    increment = () => {
        console.log("Numbers -> incremet");
        this.setState({focusedAlphabet: null}, () => this.props.dispatch(incNumbersAction()));
    };

    decrement = () => {
        console.log("Numbers -> decremet")
        this.setState({focusedAlphabet: null}, () => this.props.dispatch(decNumbersAction()));
    };

    handle = (v) => {
        this.setState({ focusedAlphabet: v});
    };

    render() {
        let counter = this.props.count;
        const listOfLetters = Object.values(letters).filter(d => d.main);

        return (
            <>
                <NumberImagesPrinter
                    count= {counter}
                    onClickIncrement={this.increment}
                    onClickDecrement={this.decrement}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
      count: state.countNumbers ? state.countNumbers : 0,
    };
  }

export default connect(mapStateToProps)(NumberSingles);
