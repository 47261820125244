import React from  'react';
import * as cartoons from "../../resources/assets/images";
import MediaCard from "../Kindergarten/MediaCard";
import tigrinyaLetters from "../../utils/tigrinyaAlphabets";
import letters from "../../utils/tigrinyaAlphabets";

const cards = [
    {key: 'alphabet-a', title: 'e', path: '/alphabet-a', img: cartoons.fidel, detail: 'nbab fidel e ms abnetat'},

];

class ReadStories extends React.PureComponent {
    render() {
        const listOfLetters = Object.values(letters).filter(d => d.main);

        return (
            <div className="alphabet-cards">
               {listOfLetters.map (card => (
                  <MediaCard key={card.key} card={card} />
               ))}
            </div>
        );
    }

}
export default ReadStories;
