import React from  'react';
import tigrinyaChart from '../../utils/tigrinyaAlphabetChart'
import { withStyles } from "@material-ui/core/styles";
import { withRouter} from 'react-router-dom';

// TODO: Fix the font import functionality
// import "../../resources/assets/fonts";
// import '../../resources/assets/fonts/index.css';
// import '../../resources/assets/fonts/fantuwua.ttf';
// import fantuwua from '../../resources/assets/fonts/fantuwua.ttf';
// import { createGlobalStyle } from "styled-components";


const styles = theme => ({
    header: {
        fontSize: "100px",
        textAlign: "center",
        fontWeight: "500",
        fontFamily: "fantuwua"
    },
    table: {
        // border: "1px solid black",
        // borderRadius: "10px",
        margin: "0 auto"
    },
    tr: {
        border: "1px solid black",
        borderRadius: "10px",
    },
    td: {
        border: "1px solid black",
        borderRadius: "10px",
        fontSize: "90px",
        textAlign: "center",
    },
    lighterRow: {
        backgroundColor: "#fff29b"
    },
    darkerRow: {
        backgroundColor: "#fded84"
    }
});


class AlphabetChart extends React.PureComponent {
    
    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={classes.header}>ትግርኛ ፊደላት</div>
                <table className={classes.table}>
                    {tigrinyaChart.alphabetBasicList.map ((alphabetSeptet, index) => (
                    // <AlphabetCard key={card.key} card={card} index={index}/>
                        <tr className={classes.tr + " " + (index%2 == 0? classes.lighterRow : classes.darkerRow)}>
                            {alphabetSeptet.alphabetArray.map ((alphabet, index) => (
                                <td className={classes.td}>{alphabet}</td>
                            )) }
                        </tr>
                    ))}
                </table>
            </div>
        );
    }
}

// export default AlphabetChart;
export default withRouter(withStyles(styles, { withTheme: true })(AlphabetChart));

