import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { toTigrinya } from "../../utils/toTigrinya";

const styles = {
  card: {
      margin: '20px',
      maxWidth: 345,
      minWidth: 345,
  },
  media: {
    height: 140,
    width: 'auto'
  }
};

class MediaCard extends React.PureComponent {
  handleClick = () => {
    this.props.history.push(this.props.card.path);
  };

  render() {
    const { card, classes } = this.props;

    return (
        <Card className={classes.card}>
          <CardActionArea onClick={this.handleClick}>
            <CardMedia className={classes.media} image={card.img} title="ክፈት" />
            <CardContent>
              <Typography gutterBottom variant="headline" component="h2">
                {toTigrinya(card.title)}
              </Typography>
              <Typography component="p">{toTigrinya(card.detail)}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
    );
  }
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(MediaCard));
