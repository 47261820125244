import React from 'react';
import { withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import PowerSettingsRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';

import AccountCircleIcon from '@material-ui/icons/AccountCircleRounded';
import {toTigrinya} from '../../utils/toTigrinya';

const styles = {
    listItemIcon: {
      color: 'black',
    },
};

class OtherListItems extends React.PureComponent {

    handleLinkToAbout = () => {
        this.props.history.push('/about');
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <ListItem button onClick={this.handleLinkToAbout}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <MailIcon />
                    </ListItemIcon>
                    <ListItemText primary={toTigrinya('melAKttat')} />
                </ListItem>
                <ListItem button onClick={this.handleLinkToAbout}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={toTigrinya("bzaObana")} />
                </ListItem>
                <ListItem button onClick={this.handleLinkToAbout}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <PowerSettingsRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary={toTigrinya('Oxewo')} />
                </ListItem>
          </div>
        );
    }
}


export default withRouter(withStyles(styles)(OtherListItems));
