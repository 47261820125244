//import * as audio from '../../resources/assets/audio';

const vowelMetadata = [
    {"romanizationSymbol": 'ä', "label": "እሁድ", "audio": ""},
    {"romanizationSymbol": 'u', "label": "ካልእ", "audio": ""},
    {"romanizationSymbol": 'i', "label": "ሳልስ", "audio": ""},
    {"romanizationSymbol": 'a', "label": "ራብዕ", "audio": ""},
    {"romanizationSymbol": 'e', "label": "ሓምስ", "audio": ""},
    {"romanizationSymbol": '(ə)', "label": "ሳድስ", "audio": ""},
    {"romanizationSymbol": 'o', "label": "ሳብዕ", "audio": ""}
];

const alphabetBasicList = [
    {"romanization_symbol": 'h', "rootAudio":'', "alphabetArray": ['ሀ','ሁ','ሂ','ሃ','ሄ','ህ','ሆ']},
    {"romanization_symbol": 'l', "rootAudio":'', "alphabetArray": ['ለ','ሉ','ሊ','ላ','ሌ','ል','ሎ']},
    {"romanization_symbol": 'ḥ', "rootAudio":'', "alphabetArray": ['ሐ','ሑ','ሒ','ሓ','ሔ','ሕ','ሖ']},
    {"romanization_symbol": 'm', "rootAudio":'', "alphabetArray": ['መ','ሙ','ሚ','ማ','ሜ','ም','ሞ']},
    {"romanization_symbol": 'r', "rootAudio":'', "alphabetArray": ['ረ','ሩ','ሪ','ራ','ሬ','ር','ሮ']},
    {"romanization_symbol": 's', "rootAudio":'', "alphabetArray": ['ሰ','ሱ','ሲ','ሳ','ሴ','ስ','ሶ']},
    {"romanization_symbol": 'š', "rootAudio":'', "alphabetArray": ['ሸ','ሹ','ሺ','ሻ','ሼ','ሽ','ሾ']},
    {"romanization_symbol": 'q', "rootAudio":'', "alphabetArray": ['ቀ','ቁ','ቂ','ቃ','ቄ','ቅ','ቆ']},
    {"romanization_symbol": 'q̱', "rootAudio":'', "alphabetArray": ['ቐ','ቑ','ቒ','ቓ','ቔ','ቕ','ቖ']},
    {"romanization_symbol": 'b', "rootAudio":'', "alphabetArray": ['በ','ቡ','ቢ','ባ','ቤ','ብ','ቦ']},
    {"romanization_symbol": 'v', "rootAudio":'', "alphabetArray": ['ቨ','ቩ','ቪ','ቫ','ቬ','ቭ','ቮ']},
    {"romanization_symbol": 't', "rootAudio":'', "alphabetArray": ['ተ','ቱ','ቲ','ታ','ቴ','ት','ቶ']},
    {"romanization_symbol": 'č', "rootAudio":'', "alphabetArray": ['ቸ','ቹ','ቺ','ቻ','ቼ','ች','ቾ']},
    {"romanization_symbol": 'n', "rootAudio":'', "alphabetArray": ['ነ','ኑ','ኒ','ና','ኔ','ን','ኖ']},
    {"romanization_symbol": 'ñ', "rootAudio":'', "alphabetArray": ['ኘ','ኙ','ኚ','ኛ','ኜ','ኝ','ኞ']},
    {"romanization_symbol": 'ʼ', "rootAudio":'', "alphabetArray": ['አ','ኡ','ኢ','ኣ','ኤ','እ','ኦ']},
    {"romanization_symbol": 'k', "rootAudio":'', "alphabetArray": ['ከ','ኩ','ኪ','ካ','ኬ','ክ','ኮ']},
    {"romanization_symbol": 'ḵ', "rootAudio":'', "alphabetArray": ['ኸ','ኹ','ኺ','ኻ','ኼ','ኽ','ኾ']},
    {"romanization_symbol": 'w', "rootAudio":'', "alphabetArray": ['ወ','ዉ','ዊ','ዋ','ዌ','ው','ዎ']},
    {"romanization_symbol": 'ʽ', "rootAudio":'', "alphabetArray": ['ዐ','ዑ','ዒ','ዓ','ዔ','ዕ','ዖ']},
    {"romanization_symbol": 'z', "rootAudio":'', "alphabetArray": ['ዘ','ዙ','ዚ','ዛ','ዜ','ዝ','ዞ']},
    {"romanization_symbol": 'ž', "rootAudio":'', "alphabetArray": ['ዠ','ዡ','ዢ','ዣ','ዤ','ዥ','ዦ']},
    {"romanization_symbol": 'y', "rootAudio":'', "alphabetArray": ['የ','ዩ','ዪ','ያ','ዬ','ይ','ዮ']},
    {"romanization_symbol": 'd', "rootAudio":'', "alphabetArray": ['ደ','ዱ','ዲ','ዳ','ዴ','ድ','ዶ']},
    {"romanization_symbol": 'ǧ', "rootAudio":'', "alphabetArray": ['ጀ','ጁ','ጂ','ጃ','ጄ','ጅ','ጆ']},
    {"romanization_symbol": 'g', "rootAudio":'', "alphabetArray": ['ገ','ጉ','ጊ','ጋ','ጌ','ግ','ጎ']},
    {"romanization_symbol": 'ṭ', "rootAudio":'', "alphabetArray": ['ጠ','ጡ','ጢ','ጣ','ጤ','ጥ','ጦ']},
    {"romanization_symbol": 'č̣', "rootAudio":'', "alphabetArray": ['ጨ','ጩ','ጪ','ጫ','ጬ','ጭ','ጮ']},
    {"romanization_symbol": 'p̣', "rootAudio":'', "alphabetArray": ['ጰ','ጱ','ጲ','ጳ','ጴ','ጵ','ጶ']},
    {"romanization_symbol": 'ṣ', "rootAudio":'', "alphabetArray": ['ጸ','ጹ','ጺ','ጻ','ጼ','ጽ','ጾ']},
    {"romanization_symbol": 'ṣ', "rootAudio":'', "alphabetArray": ['ፀ','ፁ','ፂ','ፃ','ፄ','ፅ','ፆ']},
    {"romanization_symbol": 'f', "rootAudio":'', "alphabetArray": ['ፈ','ፉ','ፊ','ፋ','ፌ','ፍ','ፎ']},
    {"romanization_symbol": 'p', "rootAudio":'', "alphabetArray": ['ፐ','ፑ','ፒ','ፓ','ፔ','ፕ','ፖ']}
];



const vowelVariationMetadata = [
    {"romanizationSymbol": 'wä', "label": "", "audio": ""},
    {"romanizationSymbol": 'wi', "label": "", "audio": ""},
    {"romanizationSymbol": 'wa', "label": "", "audio": ""},
    {"romanizationSymbol": 'we', "label": "", "audio": ""},
    {"romanizationSymbol": 'wə', "label": "", "audio": ""}
];

const alphabetVariationList = [
    {"romanization_symbol": 'q̱', "rootAudio":'', "alphabetArray": ['ቈ','ቊ','ቋ','ቌ','ቍ']},
    {"romanization_symbol": 'q̱', "rootAudio":'', "alphabetArray": ['ቘ','ቚ','ቛ','ቜ','ቝ']},
    {"romanization_symbol": 'k', "rootAudio":'', "alphabetArray": ['ኰ','ኲ','ኳ','ኴ','ኵ']},
    {"romanization_symbol": 'ḵ', "rootAudio":'', "alphabetArray": ['ዀ','ዂ','ዃ','ዄ','ዅ']},
    {"romanization_symbol": 'g', "rootAudio":'', "alphabetArray": ['ጐ','ጒ','ጓ','ጔ','ጕ']}
];

const tigrinyaAlphabetChart = {vowelMetadata, alphabetBasicList, vowelVariationMetadata, alphabetVariationList};
export default tigrinyaAlphabetChart;