import React from  'react';
import {toTigrinya} from "../../utils/toTigrinya";
import { withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import lion from '../../resources/assets/images/transparent/lion-transparent.png';
import tigrinyaLetters from '../../utils/tigrinyaAlphabets';

const styles = theme => ({
    alphabetHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '60px',
    },
    bodies: {
        display: 'flex',
        flexDirection: 'column',

    },
    body: {
        maxWidth: "800px",
        marginBottom: '30px'
    },
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '30px'
    },
    twoAlphabets: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '30px',
        margin: '25px 0'
    },
    rootWords: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        fontSize: '30px',
        lineHeight: '70px',

    },
    group: {
        fontSize: '50px',
        marginBottom: '20px'
    },
    sentencesWithImage: {
        display: 'flex',
        alignItems: 'center'
    }
});

class AlphabetA extends React.PureComponent {

    render() {
        const { classes, match } = this.props;

        console.log ("match  params id is ", match.params.id);

        return (
            <div className="alphabet">
                <div className={classes.alphabetHeader}>
                    {toTigrinya("e")}
                </div>
                <div className={classes.bodies}>
                    <div className={classes.body} style={{marginBottom: '100px'}}>
                        <span className={classes.group}>1</span>
                        <div className={classes.root}>
                            <div> {toTigrinya("e")}</div>
                            <div> {toTigrinya("u")}</div>
                            <div> {toTigrinya("i")}</div>
                            <div> {toTigrinya("a")}</div>
                            <div> {toTigrinya("ie")}</div>
                            <div> {toTigrinya("A")}</div>
                            <div> {toTigrinya("o")}</div>
                        </div>
                        <div className={classes.twoAlphabets}>
                            <div> {toTigrinya("ane")}</div>
                            <div> {toTigrinya("om")}</div>
                            <div> {toTigrinya("ila")}</div>
                            <div> {toTigrinya("Asa")}</div>
                            <div> {toTigrinya("uf")}</div>
                            <div> {toTigrinya("oo")}</div>
                            <div> {toTigrinya("aw")}</div>
                        </div>
                        <div className={classes.twoAlphabets}>
                            <div> {toTigrinya("irab")}</div>
                            <div> {toTigrinya("iertra")}</div>
                            <div> {toTigrinya("asmera")}</div>
                            <div> {toTigrinya("anbesa")}</div>
                            <div> {toTigrinya("asmera")}</div>
                            <div> {toTigrinya("amien")}</div>
                            <div>  {toTigrinya("alona")}</div>
                        </div>
                    </div>
                    <div className={classes.sentencesWithImage}>
                        <div>
                            <div className={classes.body}>
                                <span className={classes.group}>2</span>
                                <div className={classes.rootWords}>
                                    <div> {toTigrinya("irab  zelila።")}</div>
                                    <div> {toTigrinya("iertra hagerna።")}</div>
                                    <div> {toTigrinya("anbesa nediru።")}</div>
                                    <div> {toTigrinya("om HaKure።")}</div>
                                    <div> {toTigrinya("Agri guOzo።")}</div>
                                    <div> {toTigrinya("uganda keyde።")}</div>
                                    <div> {toTigrinya("asmera ketema።")}</div>
                                </div>
                            </div>
                            <div className={classes.body}>
                                <span className={classes.group}>3</span>
                                <div className={classes.rootWords}>
                                    <div> {toTigrinya("ab asmera beles beliO።")}</div>
                                    <div> {toTigrinya("kab om wediQe teharime። ")}</div>
                                    <div> {toTigrinya("irab Anssa zegedam eya።")}</div>
                                    <div> {toTigrinya("iertra tSOate bhierat alewa።")}</div>
                                    <div> {toTigrinya("anbesa ngus bereKa Ayu።")}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img style={{width: '665px'}} src={lion} alt="lion"/>
                        </div>
                    </div>


                </div>


            </div>
        );
    }

}

AlphabetA.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
}
export default withRouter(withStyles(styles, {withTheme: true})(AlphabetA));
