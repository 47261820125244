import letters from './tigrinyaAlphabets';

const vowels = ['a', 'e', 'i', 'o', 'u', "'"];

const isInArray = (value, array)=> {
  return array.indexOf(value) > -1;
};

const splitToTigrinyaAlphabets = (value) => {
  let temp = '';
  let tigAlphabets = [];

  for(let i=0; i < value.length; i++) {
    if ((/[^\w]|_/g).test(value[i])) {
      if(temp !== '' && value[i] !== "'"){
        tigAlphabets.push(temp);
      }
      if(temp !== '' && value[i] !== ' ' && value[i] !== "'"){
        tigAlphabets.push(value[i]);
      }
      if (temp !== '' && value[i] === "'") {
         temp = temp + value[i];
         tigAlphabets.push(temp);
      }
      temp = '';
    }
    if (!isInArray(value[i], vowels) && value[i] !== ' ') {
      if (temp !== '') {
        tigAlphabets.push(temp);
        temp = '';
      }
      temp = temp + value[i];
      if (i + 1 === value.length ) {
        tigAlphabets.push(temp);
        temp = '';
      }
    } else {
      temp = temp + value[i];
      if (value[i] === 'i' && value[i + 1] === 'e'){
        temp = temp + value[i + 1];
        i++;
      }
      if (temp !== "'"){
         tigAlphabets.push(temp);
      }
         temp = '';
    }
  }

  return tigAlphabets;
};

const getSadis = (value) => {

  let stem = value[0];

  if (isInArray(stem, vowels)) {
    stem = 'a';
  }

  let i= 0;
  switch(value[i]) {
    case 'e':
       return letters[stem].letter[0].lable;
   case 'u':
       return letters[stem].letter[1].lable;
   case 'i':
     if (value[i + 1] === 'e') {
       return letters[stem].letter[4].lable;
     } else {
         return letters[stem].letter[2].lable;
     }
   case 'a':
       return letters[stem].letter[3].lable;
   case 'o':
       return letters[stem].letter[6].lable;
   default:
      return typeof letters[stem] === 'undefined'? '' : letters[stem].letter[5].lable;
  }
};

const getValue = (value) => {

  if( value.length === 1) {
    const nonalphanummeric = (/[^\w]|_/g).test(value);
    return nonalphanummeric ? value : getSadis(value) ;
  }
  const stem = value[0] === " " ? value[1] : value[0];


  for(let i=1; i < value.length; i++) {
   switch(value[i]) {
     case 'e':
        return stem === 'i' ? '\u12A4' : letters[stem].letter[0].lable;
    case 'u':
        return letters[stem].letter[1].lable;
    case 'i':
      if (value[i + 1] === 'e') {
        return letters[stem].letter[4].lable;
      } else {
          return letters[stem].letter[2].lable;
      }
    case 'a':
        return letters[stem].letter[3].lable;
    case 'o':
        return letters[stem].letter[6].lable;
    default:
        return typeof letters[stem] === 'undefined'? '' : letters[stem].letter[5].lable;
   }
  }
};

/**
 * Given a string or an array of english characters it creates a string of corresponding tigrinya characters
 * ['s','e','l','a','m'] => ሰላም
 * "selam" => ሰላም
 * @param {*} array
 * @returns
 */
const toTigrinya = (array) => {
  const splitToWords = splitToTigrinyaAlphabets(array);
  let result = "";

  for (let i = 0; i < splitToWords.length; i++) {
    const tigrinyaAlaphabet = splitToWords[i];
    if ( tigrinyaAlaphabet.length > 1) {
      if (tigrinyaAlaphabet[0] === " ") {
        tigrinyaAlaphabet[0].trim();
      }
    }
    const stem = tigrinyaAlaphabet[0];
    if (!isInArray(stem, vowels) && !(/[^\w]|_/g).test(stem) && letters[stem]) {
      letters[stem].letter.sort((a, b) => a.id - b.id); // sort the letters
    }
    result += getValue(tigrinyaAlaphabet);
  }
  return result;
};

export {toTigrinya, splitToTigrinyaAlphabets};
